<template>
  <v-card
    outlined
    class="pa-5 mt-4 no-focus"
    @click="showDetails = !showDetails"
  >
    <div class="d-flex align-center">
      <div class="d-flex">
        <v-icon
          class="static-gradient-background rounded-circle pa-2"
          color="white"
          x-large
        >
          mdi-email-fast
        </v-icon>
        <div class="d-flex flex-column align-center justify-center px-4">
          <span class="title grey--text font-weight-light text-no-wrap">{{ $t('acquisition-booster.title') }}</span>
        </div>
      </div>

      <div
        v-if="totalInvitationsCount>0"
        class="pb-0 headline font-weight-medium d-flex w-full"
      >
        <v-spacer />

        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex align-center"
        >
          <v-row>
            <v-col
              :cols="7"
              class="d-flex align-center"
            >
              <InvitationStatistics
                :total-invitations-count="totalInvitationsCount"
                :interacted-invitations-count="interactedInvitationsCount"
              />
            </v-col>
            <v-col cols="1" />
            <v-col
              cols="4"
              class="d-flex align-center"
            >
              <v-btn
                color="primary"
                @click.stop="triggerAppcuesFlow({slug:'invite-contact'})"
              >
                {{ $t('buttons.acquisition-booster.cta') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <v-spacer />
        <v-btn
          v-if="hasMultipleAcquisitionBoosterInvitations"
          icon
          small
        >
          <v-icon>
            {{ showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </div>

      <InitialAcquisitionBooster
        v-if="totalInvitationsCount<=0 && $vuetify.breakpoint.mdAndUp"
        class="ml-8"
      />
    </div>

    <InitialAcquisitionBooster
      v-if="totalInvitationsCount<=0 && $vuetify.breakpoint.smAndDown"
      class="mt-8"
    />

    <div v-if="totalInvitationsCount>0">
      <div
        v-if="$vuetify.breakpoint.smAndDown"
        class="d-flex flex-column mt-6"
      >
        <InvitationStatistics
          :total-invitations-count="totalInvitationsCount"
          :interacted-invitations-count="interactedInvitationsCount"
        />
        <v-btn
          color="primary"
          class="mt-6"
          @click.stop="triggerAppcuesFlow({slug:'invite-contact'})"
        >
          {{ $t('buttons.acquisition-booster.cta') }}
        </v-btn>
      </div>

      <v-expand-transition v-if="hasMultipleAcquisitionBoosterInvitations">
        <div
          v-show="showDetails"
          class="mt-5"
        >
          <v-divider />

          <v-card-text class="px-0 pb-0 mx-0 mb-0 mt-2">
            <v-row
              v-if="$vuetify.breakpoint.smAndUp"
            >
              <v-col
                sm="5"
                class="py-0 text-center grey--text"
              >
                <span class="mr-1 subtitle-1 grey--text text--darken-3" />
              </v-col>
              <v-col
                sm="3"
                class="py-0 d-flex justify-center grey--text"
                :class="{'d-flex justify-space-between w-full':$vuetify.breakpoint.xs}"
              >
                <span>{{ $t('labels.acquisition-booster.invitations') }}</span>
              </v-col>
              <v-col
                sm="3"
                class="py-0 d-flex justify-center grey--text"
                :class="{'d-flex justify-space-between w-full':$vuetify.breakpoint.xs}"
              >
                <span>{{ $t('labels.acquisition-booster.interactions') }}</span>
              </v-col>
            </v-row>
            <v-row
              v-for="({landingpageSlug, landingpageName, total, conversions} ) of invitationsPerLandingpage"
              :key="landingpageSlug"
              :class="{'d-flex flex-column':$vuetify.breakpoint.xs}"
            >
              <v-col
                sm="5"
                class="py-0 grey--text"
              >
                <span class="mr-1 subtitle-1 grey--text text--darken-3">{{ landingpageName }}</span>
              </v-col>
              <v-col
                sm="3"
                class="py-0 d-flex justify-center grey--text"
                :class="{'justify-space-between w-full':$vuetify.breakpoint.xs}"
              >
                <span v-if="$vuetify.breakpoint.xs">{{ $t('labels.acquisition-booster.invitations') }}</span>
                <span class="mr-1 subtitle-1 grey--text text--darken-3">{{ total }}</span>
              </v-col>
              <v-col
                sm="3"
                class="py-0 d-flex justify-center grey--text"
                :class="{'justify-space-between w-full':$vuetify.breakpoint.xs}"
              >
                <span v-if="$vuetify.breakpoint.xs">{{ $t('labels.acquisition-booster.interactions') }}</span>
                <span class="mr-1 subtitle-1 grey--text text--darken-3">{{ conversions }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-expand-transition>
    </div>
  </v-card>
</template>

<script>
import InvitationStatistics from './InvitationStatistics.vue'
import InitialAcquisitionBooster from './InitialAcquisitionBooster.vue'
import { triggerAppcuesFlow } from '@/lib/appcues'

export default {
  components: {
    InvitationStatistics,
    InitialAcquisitionBooster
  },
  props: {
    invitationStatisticsForCompany: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showDetails: false
    }
  },
  computed: {
    invitationsPerLandingpage () {
      return this.invitationStatisticsForCompany?.invitationsPerLandingpage
    },
    hasMultipleAcquisitionBoosterInvitations () {
      return this.invitationsPerLandingpage?.length > 1
    },
    totalInvitationsCount () {
      return this.invitationStatisticsForCompany?.total
    },
    interactedInvitationsCount () {
      return this.invitationStatisticsForCompany?.conversions
    }
  },
  methods: {
    triggerAppcuesFlow
  }
}
</script>
<style scoped>
.no-focus:focus:before{
  background: none;
}
</style>

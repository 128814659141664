<template>
  <v-row
    class="d-flex align-center"
  >
    <v-col
      cols="12"
      sm="6"
    >
      <div
        class="d-flex align-center "
        :class="{'flex-row justify-space-between w-full':$vuetify.breakpoint.smAndDown, 'flex-column px-2': $vuetify.breakpoint.mdAndUp}"
      >
        <p
          class="text-body-1 item text-no-wrap text--darken-3 pl-1 mb-0 pb-0"
        >
          {{ $t('labels.acquisition-booster.invitations') }}
        </p>
        <v-progress-circular
          v-if="!hasInvitationsCount"
          indeterminate
          :size="25"
        />
        <p
          v-else
          class="mb-0 pb-0"
        >
          <b>{{ totalInvitationsCount }}</b>
        </p>
      </div>
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <div
        class="d-flex align-center"
        :class="{'flex-row justify-space-between w-full':$vuetify.breakpoint.smAndDown, 'flex-column px-2': $vuetify.breakpoint.mdAndUp}"
      >
        <p
          class="text-body-1 item text-no-wrap text--darken-3 pl-1 mb-0 pb-0"
        >
          {{ $t('labels.acquisition-booster.interactions') }}
        </p>
        <v-progress-circular
          v-if="!hasInvitationsCount"
          indeterminate
          :size="25"
        />
        <p
          v-else
          class="mb-0 pb-0"
        >
          <b>{{ interactedInvitationsCount }}</b>
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    totalInvitationsCount: {
      type: Number,
      default: 0
    },
    interactedInvitationsCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    hasInvitationsCount () {
      return this.totalInvitationsCount >= 0
    }
  }
}
</script>
